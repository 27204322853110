<template>
  <div class="province-checkbox">
    <div class="search-warpper">
      <el-input
        size="mini"
        v-model="province"
        placeholder="请输入省份名称或首字母 (不区分大小写) 进行检索"
        style="width: 320px"
        clearable
      ></el-input>
    </div>
    <div class="continent-select-warpper">
      <div v-for="data in finalList" :key="data.label">
        <span class="mr10 letter">{{ data.label }}</span>
        <el-checkbox-group v-model="checkedIdList">
          <el-checkbox
            v-for="item in data.children"
            :key="item.value"
            :label="item.value"
            :disabled="disabledIdList.includes(item.value)"
            @change="onchecked(item, $event)"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import checkMixin from '@/mixins/checkMixin'
import { REQUEST_ALL_DATA } from '@/utils/constant'
import { getProvinceList } from '@/api/template'
import { flat } from '@/utils'

export default {
  mixins: [checkMixin],
  model: {
    prop: 'checkedList'
  },
  props: {
    checkedList: {
      type: Array,
      default: () => []
    },
    disabledIdList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      province: ''
    }
  },
  computed: {
    // 格式化后端返回的数据
    formatData({ data }) {
      const obj = {}
      const arr = []
      data.forEach((v) => {
        if (!obj[v.initial]) obj[v.initial] = []
        obj[v.initial].push(v)
      })
      Object.keys(obj).forEach((v) => {
        arr.push({
          label: v,
          charCode: v.charCodeAt(0),
          children: obj[v]
        })
      })
      return arr.sort((a, b) => {
        return a.charCode - b.charCode
      })
    },
    // 由搜索条件-数据驱动视图绑定的返回数据
    finalList() {
      if (this.province.length === 0) return this.formatData
      else {
        this.province = this.province.toUpperCase()
        if (this.province.length === 1 && 65 <= this.province.charCodeAt(0) && 90 >= this.province.charCodeAt(0)) {
          return this.formatData.filter((item) => item.label === this.province)
        } else {
          const res = flat(
            this.formatData.map((item) => {
              return item.children.map((cur) => {
                if (cur.label === this.province) return item
              })
            })
          ).filter(Boolean)
          return res.length === 0 ? [] : res
        }
      }
    }
  },
  watch: {
    checkedList: {
      handler(n) {
        this.checkedAllIdList = this.map(n, 'value')
      },
      immediate: true,
      deep: true
    }
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      const data = {
        level: 1,
        ...REQUEST_ALL_DATA
      }
      this.loading = true
      const res = await awaitResolve(getProvinceList(data))
      this.loading = false
      if (!res) return

      // 为省市打上首字母字段标签
      this.data = this.handleData(res.detail || [])
    },
    handleData(arr) {
      return arr.map((item) => {
        item.initial = item.threeCharCode.slice(0, 1).toUpperCase()
        item.label = item.areaCnName
        item.value = item.id
        return item
      })
    },

    onchecked(item, checked) {
      this.handleChecked(item, checked)

      this.oninput()
    },

    oninput() {
      // console.log('oninput')
      this.$emit('input', this.checkedAllList)
    }
  }
}
</script>

<style lang="scss" scoped>
.province-checkbox ::v-deep {
  width: 527px;
  border-right: 1px solid #dcdee0;
  border-bottom: 1px solid #dcdee0;
  .continent-select-warpper {
    padding: 16px 0 0 16px;
    height: 430px;
    overflow: auto;
    .letter {
      margin-right: 10px !important;
      width: 10px;
      display: inline-block;
    }
    .el-checkbox__label {
      margin-right: 10px;
    }
    .all {
      .el-checkbox__label {
        padding-right: 25px;
      }
    }
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
      border: 1px solid #c8c9cc;
      border-radius: 2px;
    }
    .el-checkbox-group {
      display: inline;
    }
    .el-checkbox__label {
      padding-left: 5px;
    }
    .el-checkbox {
      color: #595961;
      font-weight: normal;
      margin: 0px 3px 13px 0px;
    }
  }
  .search-warpper {
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid #dcdee0;
    padding-left: 16px;
  }
}
</style>
